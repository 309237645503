import Accordion from "./modules/accordion";
import Tabs from "./modules/tabs";
import OverlayMenu from "./modules/overlay-menu";
import Animate from "./modules/animate";
import VideoAutoplay from "./modules/video-autoplay";
import Counter from "./modules/counter";
import GalleryNav from "./modules/gallery-nav";
import HorizontalScroll from "./modules/horizontal-scroll";
import TargetMenu from "./modules/target-menu";
import Prelaod from "./modules/load-styles-fix";
import { dialog } from "./modules/dialog";
import CopyLink from "./modules/copy-link";
import AjaxMorePosts from "./modules/ajax-more-posts";

OverlayMenu();

Accordion();

Tabs();

VideoAutoplay();

Counter();

Animate();

GalleryNav()

TargetMenu()

Prelaod()

dialog()

CopyLink()

AjaxMorePosts()

HorizontalScroll()


const tables = document.querySelectorAll("table")
if (tables.length) {
    tables.forEach(el => {
        const wrap = document.createElement('div');
        wrap.classList.add('table-responsive');
        el.parentNode.insertBefore(wrap, el);
        wrap.appendChild(el)
    })
}

