const GalleryNav = () => {
    const galleriesContainer = document.querySelectorAll('.galleries');
  
    if (!galleriesContainer.length) return;
  
    galleriesContainer.forEach(container => {
      const buttons = container.querySelectorAll('.button');
      const galleries = container.querySelectorAll('.gallery');
  
      buttons.forEach(button => {
        const galleryName = button.dataset.galleryName;
  
        button.addEventListener('click', function (e) {
          if (!this.classList.contains('active')) {
            buttons.forEach(btn => btn.classList.remove('active'));
            this.classList.add('active');
  
            galleries.forEach(gallery => {
              const isActive = gallery.dataset.galleryName === galleryName;
              gallery.classList.toggle('active', isActive);
            });
          }
        });
      });
    });
  };
  
  export default GalleryNav;
  