/*
 * Usage:
 * 1. Add `data-dialog-container` attribute to the container of the dialog box.
 * 2. Add `data-dialog-open` attribute to button/s that open the dialog box.
 * 3. Add `data-dialog-close` attribute to button/s that close the dialog box.
 *
 * Example:
 * <button data-dialog-open type="button">Open dialog</button>
 *
 * <div data-dialog-container>
 *   <h2>Dialog title</h2>
 *   <button data-dialog-close type="button">Close</button>
 * </div>
 *
 * Explanation:
 * This code creates a simple dialog box that can be opened and closed using buttons with specific attributes. Clicking the button/s with `data-dialog-open` attribute will show the dialog box (container with `data-dialog-container` attribute). Clicking the button/s with `data-dialog-close` attribute will hide the dialog box.
 */

//Inner East Community Finance start
const popupTitle = document.getElementById('popup-title')
const popupSubtitle = document.getElementById('popup-subtitle')
const popupImage = document.getElementById('popup-image')
const popupContent = document.getElementById('popup-content')
const popupSocials = document.getElementById('popup-socials')
//Inner East Community Finance end

export function dialog() {
    // Get DOM elements
    const openButtons = document.querySelectorAll('[data-dialog-open]')
    const closeButtons = document.querySelectorAll('[data-dialog-close]')
    const dialogContainer = document.querySelector('[data-dialog-container]')
    // Return early if any required element is missing
    if (!openButtons || !closeButtons || !dialogContainer) {
        return
    }

    // Set the anchor point to the button that was used to open the dialog box so that we can return the focus to it later.
    let focusAnchor

    // Set focusable elements
    let focusableElements
    let firstFocusableElement
    let lastFocusableElement

    // Create and append backdrop
    const backdrop = createBackdrop()

    // Set attributes for accessibility
    dialogContainer.setAttribute('role', 'dialog')
    dialogContainer.setAttribute('aria-modal', 'true')

    // Add event listeners
    openButtons.forEach(openButton => {
        openButton.addEventListener('click', e => {
            e.preventDefault()
            openDialog()
            focusAnchor = openButton

            //Inner East Community Finance - start
            const content = openButton.nextElementSibling?.innerHTML // closest hidden content
            const title = openButton.querySelector('.card-member__title')?.innerText
            const subtitle = openButton.querySelector('.card-member__subtitle')?.innerText
            const image = openButton.querySelector('.card-member__img')?.innerHTML
            const socials = openButton.nextElementSibling.nextElementSibling?.innerHTML;


            popupTitle.innerText = title ?? ''
            popupSubtitle.innerText = subtitle ?? ''
            popupImage.innerHTML = image ?? ''
            popupContent.innerHTML = content ?? ''
            popupSocials.innerHTML = socials ?? ''
            //Inner East Community Finance - end

            // Get focusable elements inside dialog container
            focusableElements = dialogContainer.querySelectorAll(
                'button, a[href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            )
            firstFocusableElement = focusableElements[0]
            lastFocusableElement = focusableElements[focusableElements.length - 1]
            openButton.addEventListener('keydown', focusFirstElementIfMouseOpen, { once: true })
        })

        openButton.addEventListener('keydown', focusFirstElement)
    })

    closeButtons.forEach(closeButton => {
        closeButton.addEventListener('click', closeDialog)
    })

    backdrop.addEventListener('click', closeDialog)
    dialogContainer.addEventListener('keydown', focusTrap)

    // Close dialog with Esc key
    function closeWithEscHandler(e) {
        if (e.code === 'Escape') closeDialog()
    }

    // Set focus on first element
    function focusFirstElement(e) {
        if (e.code === 'Enter' || e.code === 'Space')
            dialogContainer.addEventListener('transitionend', () => firstFocusableElement.focus(), { once: true })
    }

    // Set focus on first element and trap focus when opening dialog with mouse and then trying to navigate with keyboard
    function focusFirstElementIfMouseOpen(e) {
        if (e.code === 'Tab') {
            firstFocusableElement.focus()
            focusTrap(e)
        }
    }

    // Open dialog and focus first element
    function openDialog() {
        dialogContainer.classList.add('active')
        backdrop.classList.add('active')
        // document.documentElement.classList.toggle("is-locked")
        document.addEventListener('keydown', closeWithEscHandler)
    }

    // Close dialog and and return focus
    function closeDialog() {
        dialogContainer.classList.remove('active')
        backdrop.classList.remove('active')
        document.removeEventListener('keydown', closeWithEscHandler)
        // document.documentElement.classList.toggle("is-locked")

        focusAnchor.focus({preventScroll: true})
        // scrollbar position fix
        dialogContainer.addEventListener('transitionend', () => (dialogContainer.scrollTop = 0), { once: true })
    }

    function createBackdrop() {
        const backdrop = document.createElement('div')
        backdrop.classList.add('dialog__backdrop')
        dialogContainer.before(backdrop)
        return backdrop
    }

    // Trap focus inside dialog container
    function focusTrap(e) {
        if (e.code === 'Tab' && e.shiftKey && document.activeElement === firstFocusableElement) {
            e.preventDefault()
            lastFocusableElement.focus()
        } else if (e.code === 'Tab' && !e.shiftKey && document.activeElement === lastFocusableElement) {
            e.preventDefault()
            firstFocusableElement.focus()
        }
    }
}
