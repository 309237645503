const Counter = () => {
  const els = document.querySelectorAll("[data-counter-target]");
  if (els.length) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            function counter(el, duration) {
              const targetString = el.dataset.counterTarget;
              const targetNumber = parseFloat(targetString.match(/\d+/)[0]);
              const textWrap = el.querySelector('.custom-counter__counter');
              const fullText = textWrap.textContent;
              const startNumber = parseFloat(fullText.match(/\d+/)[0]);
              const prefix = fullText.split(/\d+/)[0];
              const suffix = fullText.split(/\d+/)[1];
              const startTime = performance.now();


              function updateCounter(currentTime) {
                const elapsedTime = currentTime - startTime;
                const progress = Math.min(elapsedTime / duration, 1);
                const value = Math.floor(
                  progress * (targetNumber - startNumber)
                );

                textWrap.textContent = prefix + value ;

                if (progress < 1) {
                  requestAnimationFrame(updateCounter);
                }
              }

              requestAnimationFrame(updateCounter);
            }

            counter(entry.target, 1000);
            observer.unobserve(entry.target);
          }
        });
      },
      {
        rootMargin: "-30px",
      }
    );
    els.forEach((el) => {
      observer.observe(el);
    });
  }
};
export default Counter;
