const TargetMenu = () => {
    const spySections = document.querySelectorAll("[data-spy-section]");
    const spyNav = document.querySelector("[data-spy-nav]");
    const header = document.querySelector(".site-header");
    const progressBar = document.querySelector('[data-spy-progress]');

    if (!spySections.length && !spyNav) return;

    const activeClass = "active";
    let isoInstance;
    //Handler
    const isoHandler = entry => {
        const spyLink = document.querySelector(`[data-spy-link][href="#${entry.target.id}"]`);
        entry.isIntersecting ? spyLink.classList.add(activeClass) : spyLink.classList.remove(activeClass);
    };

    //Options
    const isoOptions = () => {
        const headerHeight = header.offsetHeight;
        const topOffset = Math.ceil(headerHeight * 2);
        const bottomOffset = Math.ceil(window.innerHeight - topOffset);
        return { rootMargin: `-${topOffset}px 0px -${bottomOffset}px 0px` };
    };

    //Main Function
    const iso = () => {
        if (isoInstance) isoInstance.disconnect();
        isoInstance = new IntersectionObserver(entries => entries.forEach(isoHandler), isoOptions());
        spySections.forEach(item => isoInstance.observe(item));
    };

    //Init
    const resizeObserver = new ResizeObserver(entries => entries.forEach(() => iso()));
    resizeObserver.observe(spyNav);
    resizeObserver.observe(document.querySelector("[data-spy-window-height]"));

    // For the Progress Bar
    const getRectTop = elem => window.scrollY + elem.getBoundingClientRect().top;
    const getRectBottom = elem => window.scrollY + elem.getBoundingClientRect().bottom;

    const updateProgressBar = () => {
        const headerHeight = header.offsetHeight;
        const windowHeight = window.innerHeight;
        const scrollPosition = window.scrollY;
        const startProgress = getRectTop(spySections[0]) - headerHeight * 2;
        const endProgress = getRectBottom(spySections[spySections.length - 1]) - windowHeight;
        let progress = 0;

        if (scrollPosition <= startProgress || scrollPosition >= endProgress) {
            progressBar.style.setProperty('--progress-width', `${scrollPosition >= endProgress ? 100 : 0}%`);
            return;
        }

        progress = ((scrollPosition - startProgress) / (endProgress - startProgress)) * 100;
        progressBar.style.setProperty('--progress-width', `${progress.toFixed(2)}%`);
    };

    updateProgressBar();
    window.addEventListener('scroll', updateProgressBar);
    window.addEventListener('resize', updateProgressBar);

    document.addEventListener('DOMContentLoaded', () => {
        if (window.location.hash) {
            const hash = window.location.hash.substring(1);
            const targetElement = document.getElementById(hash);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
            history.replaceState(null, null, ' ');
        }
    });

    window.addEventListener('hashchange', () => {
        history.replaceState(null, null, ' ');
    })
};

export default TargetMenu;
