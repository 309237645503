
const AjaxMorePosts = () => {
    const btn = document.querySelector('#load-more-posts');
    let page = 2;

    if (btn) {
        btn.addEventListener('click', async function () {
            try {
                const { ajaxurl, max_pages, query, post_type } = posts_ajax_obj;
                const url = `${ajaxurl}?action=load_posts_by_ajax&query=${query}&post_type=${post_type}&current_page=${page}`;
                
                const response = await fetch(url);

                if (response.status >= 200 && response.status < 400) {

                    const data = await response.text();
                    const container = document.getElementById('post-container');
                    container.insertAdjacentHTML('beforeend', data);

                    if (page >= max_pages) {
                        btn.remove();
                    }
                    page++;
                }
            } catch (error) {
                console.log('An error occurred:', error);
            }
        });        
    }

}

export default AjaxMorePosts;