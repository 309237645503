const CopyLink = () => {
    const copyLink = document.querySelector('#copy-link');
    if (copyLink) {
        copyLink.addEventListener('click', async function () {
            try {
                this.classList.remove('link-copied');
                await navigator.clipboard.writeText(window.location.href);
                this.classList.add('link-copied');
            } catch (err) {
                console.error(err);
            }
        });
    }
}

export default CopyLink;
